import { EnvironmentContext } from "components/Environment/contexts/EnvironmentContext";
import { endOfDay, startOfDay } from "date-fns";
import {
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { groupBy, noop, partition, sortBy } from "lodash";
import {
  DB,
  FirestoreDoc,
  useFirestoreCollection,
} from "providers/FirestoreProvider";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { ErrorBoundary } from "react-error-boundary";
import { MonitorRanking } from "shared/assessment/constants";
import { toAssessmentPath } from "shared/assessment/helper";
import { ALL_SCOPE_SENTINEL } from "shared/types/assessment";
import { isa } from "shared/types/is";
import { mapWith } from "shared/util/collections";
import {
  DateParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import { staticError } from "utils/console";

import {
  PresetMonitors,
  convertSavedMonitor,
} from "../../../shared/assessment/issues/presets";
import {
  Finding,
  FindingState,
} from "../../../shared/types/assessment/finding";
import {
  Monitor,
  SavedMonitor,
} from "../../../shared/types/assessment/monitor";
import { Tenant } from "../../Login";
import { ScopeContext } from "./ScopeContext";
import { SelectedEnvironmentContext } from "./SelectedEnvironmentContext";

export type MonitorWithFindings = Monitor & {
  /** All findings for the current findings select */
  scopedFindings: FirestoreDoc<Finding>[];
  /** Findings for the current select, but for all possible scopes */
  findingsByScope: Record<string, FirestoreDoc<Finding>[]>;
  monitorId: string;
  isCustom?: boolean;
  archived?: boolean;
};

export type AssessmentFindings = {
  /** Mapping of monitor ID to corresponding findings */
  allMonitors: Record<string, MonitorWithFindings>;
  archivedMonitors: MonitorWithFindings[];
  findingParams: string;
  isPartial: boolean;
  loading: boolean;
  /** Priority-sorted list of monitors that have findings, filtered by findings select */
  prioritized: MonitorWithFindings[];
  range: DateRange;
  setRange: (range: DateRange) => void;
  setState: (value: string) => void;
  setTrigger: (value: string) => void;
  state: FindingState;
  trigger: string;
};

type DateRange = [Date | undefined, Date | undefined];

export const MAX_DISPLAYED_FINDINGS = 1e3;

const defaultFindingsContextValue: AssessmentFindings = {
  allMonitors: {},
  archivedMonitors: [],
  findingParams: "",
  isPartial: false,
  loading: true,
  prioritized: [],
  range: [undefined, undefined],
  setRange: noop,
  setState: noop,
  setTrigger: noop,
  state: "open",
  trigger: "all",
};

export const FindingsContext = createContext<AssessmentFindings>(
  defaultFindingsContextValue
);

const inScope = (scopeKey: string) => (finding: FirestoreDoc<Finding>) =>
  scopeKey === ALL_SCOPE_SENTINEL || finding.data.scopeKey === scopeKey;

export const convertPresetsToMonitorsWithFindings = (
  findingsMap: Record<string, FirestoreDoc<Finding>[]>,
  scopeKey: string
) => {
  const output: Record<string, MonitorWithFindings> = {};
  for (const monitorId of Object.keys(PresetMonitors)) {
    const findings = findingsMap[monitorId] ?? [];
    output[monitorId] = {
      ...(PresetMonitors[monitorId as keyof typeof PresetMonitors] as Monitor),
      scopedFindings: findings.filter(inScope(scopeKey)),
      findingsByScope: groupBy(findings, (f) => f.data.scopeKey),
      monitorId,
    };
  }
  return output;
};

const isInRange = (
  trigger: Date,
  start: Date | null | undefined,
  end: Date | null | undefined
) =>
  // start & end are day-aligned, so broaden range to day starts and day ends
  (!start || trigger >= startOfDay(start)) &&
  (!end || trigger <= endOfDay(end));

export const FindingsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const fallback = (
    <FindingsContext.Provider
      value={{ ...defaultFindingsContextValue, loading: false }}
    >
      {children}
    </FindingsContext.Provider>
  );

  const { hasEnvironments } = useContext(EnvironmentContext);
  return hasEnvironments ? (
    <ErrorBoundary fallback={fallback}>
      <Provider>{children}</Provider>
    </ErrorBoundary>
  ) : (
    fallback
  );
};

const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [isPartial, setIsPartial] = useState(false);
  const [allMonitors, setAllMonitors] = useState<
    Record<string, MonitorWithFindings>
  >({});

  const { last } = useContext(SelectedEnvironmentContext);
  const { scopeKey } = useContext(ScopeContext);
  const { selected } = useContext(EnvironmentContext);
  const assessmentId = selected?.assessmentId;
  const tenantId = useContext(Tenant);

  const [trigger, setTrigger] = useQueryParam(
    "trigger",
    withDefault(StringParam, "all")
  );
  const [start, setStart] = useQueryParam("start", DateParam);
  const [end, setEnd] = useQueryParam("end", DateParam);
  const [state, setState] = useQueryParam(
    "state",
    withDefault(StringParam, "open")
  );
  const setRange = useCallback(
    ([start, end]: DateRange) => {
      setStart(start);
      setEnd(end);
    },
    [setEnd, setStart]
  );

  const findingParams = `state=${state}&trigger=${trigger}${
    scopeKey !== ALL_SCOPE_SENTINEL ? `&scope=${scopeKey}` : ""
  }`;

  const assessmentPath = assessmentId
    ? toAssessmentPath(tenantId, assessmentId)
    : undefined;
  const { docs: customMonitors } = useFirestoreCollection<SavedMonitor>(
    assessmentPath ? `${assessmentPath}/monitors` : undefined,
    { live: true }
  );

  const monitors = useMemo(
    () => ({
      ...(PresetMonitors as Record<string, Monitor>),
      ...Object.fromEntries(
        (customMonitors ?? []).map(({ data, id }) => [
          id,
          convertSavedMonitor(data),
        ])
      ),
    }),
    [customMonitors]
  );

  useGuardedEffect(
    (cancellation) => async () => {
      setIsLoading(true);
      setIsPartial(false);
      const monitorsOutput: Record<string, MonitorWithFindings> = {};
      setAllMonitors({});

      const loadFindings = async (monitorId: string, limitToScope: boolean) => {
        const constraints: QueryConstraint[] = [
          where("monitorId", "==", monitorId),
          where("state", "==", state),
          ...(scopeKey !== ALL_SCOPE_SENTINEL && limitToScope
            ? [where("scopeKey", "==", scopeKey)]
            : []),
          orderBy("humanId", "desc"),
          limit(MAX_DISPLAYED_FINDINGS + 1),
        ];
        const result = assessmentPath
          ? await getDocs(
              query(collection(DB, assessmentPath, "findings"), ...constraints)
            )
          : [];
        return result as QuerySnapshot<Finding>;
      };

      const promises = Object.entries(monitors).map(async ([id, monitor]) => {
        const result = await loadFindings(id, false);
        let data = result.docs as QueryDocumentSnapshot<Finding>[];
        if (data.length > MAX_DISPLAYED_FINDINGS) {
          cancellation.guard(setIsPartial)(true);
          if (scopeKey !== ALL_SCOPE_SENTINEL) {
            const scoped = await loadFindings(id, true);
            data = [
              // Put scoped findings first as we slice the front of the array
              // when data are overrun
              ...scoped.docs,
              ...data.filter((v) => v.data().scopeKey !== scopeKey),
            ];
          }
        }
        const first = data.slice(0, MAX_DISPLAYED_FINDINGS);
        return { id, monitor, data: first };
      });

      if (cancellation.isCancelled) return;

      for (const { id, monitor, data } of await Promise.all(promises)) {
        const findings: FirestoreDoc<Finding>[] = mapWith(data, function* (d) {
          const data = d.data();
          if (
            isInRange(new Date(data.trigger.at), start, end) &&
            (trigger !== "new" || data.trigger.jobId === last.doc?.data.jobId)
          ) {
            yield { id: d.id, data, ref: d.ref };
          }
        });
        monitorsOutput[id] = {
          ...monitor,
          scopedFindings: findings.filter(inScope(scopeKey)),
          findingsByScope: groupBy(findings, (f) => f.data.scopeKey),
          isCustom: customMonitors?.some((item) => item.id === id),
          archived: "archived" in monitor ? monitor.archived : false,
          monitorId: id,
        };
      }

      if (cancellation.isCancelled) return;

      setAllMonitors(monitorsOutput);
      setIsLoading(false);
    },
    [
      assessmentPath,
      state,
      scopeKey,
      start,
      end,
      trigger,
      last.doc?.data.jobId,
      customMonitors,
      monitors,
    ],
    staticError
  );

  const [prioritized, archivedMonitors] = useMemo(() => {
    const sorted = sortBy(
      Object.values(allMonitors),
      (i) => MonitorRanking[i.priority]
    );
    const withFindings = sorted.filter((s) => !!s.scopedFindings.length);
    return partition(withFindings, (monitor) => !monitor.archived);
  }, [allMonitors]);

  return (
    <FindingsContext.Provider
      value={{
        allMonitors,
        archivedMonitors,
        findingParams,
        isPartial,
        loading: isLoading,
        prioritized,
        range: [start ?? undefined, end ?? undefined],
        setRange,
        setState,
        setTrigger,
        state: isa(FindingState, state) ? state : "open",
        trigger,
      }}
    >
      {children}
    </FindingsContext.Provider>
  );
};
