import { EditOutlined } from "@ant-design/icons";
import { Alert, Button, Spin, Typography } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import { EnvironmentContext } from "components/Environment/contexts/EnvironmentContext";
import { DateHistogram } from "components/Histogram/Histogram";
import { Tenant } from "components/Login";
import { VerticalSpacedDiv } from "components/divs";
import { subDays } from "date-fns";
import { User } from "firebase/auth";
import { useFlags } from "launchdarkly-react-client-sdk";
import { compact, mapValues, sortBy } from "lodash";
import pluralize from "pluralize";
import { FirestoreDoc, useFirestoreDoc } from "providers/FirestoreProvider";
import { useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router";
import { toAssessmentPath } from "shared/assessment/helper";
import { AppPaths } from "shared/routes/constants";
import { ALL_SCOPE_SENTINEL, toKey } from "shared/types/assessment";
import { AnyNode, AssessmentNodes } from "shared/types/assessment/data";
import {
  Finding,
  FindingNode,
  FindingState,
} from "shared/types/assessment/finding";
import { Monitor, MonitorScope } from "shared/types/assessment/monitor";
import { QueryParamProvider } from "use-query-params";
import { StringParam, useQueryParam } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import {
  ConnectedNode,
  DirectedGraph,
  Node,
} from "../../../shared/graph/types";
import { fromGrantKey } from "../../../shared/integrations/resources/gcloud/assessment";
import { Heading } from "../../Heading";
import { AssessmentGraph } from "../components/AssessmentGraph";
import { EditMonitorModal } from "../components/EditMonitorModal";
import { FindingsAssignModal } from "../components/FindingsAssignModal";
import { FindingsFixOrRevertModal } from "../components/FindingsFixOrRevertModal";
import { FindingsIgnoreModal } from "../components/FindingsIgnoreModal";
import { FindingsManageModal } from "../components/FindingsManageModal";
import { FindingsSelect } from "../components/FindingsSelect";
import { ScopeScoring } from "../components/ScopeSelect";
import { DetailsColumn } from "../components/cells/DetailsColumn";
import { SinceColumn } from "../components/cells/SinceColumn";
import { MonitorSeverity } from "../components/monitor/MonitorSeverity";
import {
  FindingsContext,
  MAX_DISPLAYED_FINDINGS,
  MonitorWithFindings,
} from "../contexts/FindingsContext";
import { ScopeContext } from "../contexts/ScopeContext";
import { SelectedEnvironmentContext } from "../contexts/SelectedEnvironmentContext";
import { useControls } from "../hooks/useControls";
import { useTracker } from "../hooks/useTracker";
import { FindingDetail } from "./FindingDetail";

export type MonitorActionProps = {
  actOn: FirestoreDoc<Finding>[];
  allNodes: AnyNode[] | undefined;
  count: number;
  findingFor: (node: AnyNode) => FirestoreDoc<Finding> | undefined;
  integration: MonitorScope;
  monitor: MonitorWithFindings;
  selectedNodes: AnyNode[] | undefined;
  state: FindingState;
  user?: User;
};

export const MonitorResults: React.FC = () => {
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <MonitorResultsContent />
    </QueryParamProvider>
  );
};

const hasFixModal = (
  integration: MonitorScope,
  monitor: Monitor | undefined,
  state: FindingState
) =>
  !!integration &&
  !!monitor &&
  !!(
    (monitor.fix?.[integration] && state === "open") ||
    (monitor.revert?.[integration] && state === "resolved")
  );

const hasManageModal = (
  integration: MonitorScope,
  monitor: Monitor | undefined
) => {
  return !!integration && !!monitor && monitor.management;
};
const hasIgnoreToggle = (state: FindingState) =>
  state === "open" || state === "ignored";

const hasAssignModal = (
  state: FindingState,
  findings: FirestoreDoc<Finding>[]
) => state === "open" && findings.find((f) => !f.data.issue);

const syntheticNode = (
  node: FindingNode
): ConnectedNode<AssessmentNodes, keyof AssessmentNodes> | undefined => {
  let out;
  switch (node.type) {
    case "grant":
      {
        out = { ...node, data: fromGrantKey(node.key) };
      }
      break;
    case "identity":
      out = { ...node, data: {} };
      break;
    default:
      return undefined;
  }
  return { ...out, children: [], parents: [] };
};

const ActionBar: React.FC<Omit<MonitorActionProps, "actOn" | "count">> = (
  props
) => {
  const {
    assessmentFindingAssignment: hasAssignment,
    assessmentManage: hasManagement,
  } = useFlags();
  const { count, actOn } = useMemo(() => {
    const count = props.selectedNodes?.length ?? 0;
    const nodes = count ? props.selectedNodes : props.allNodes;
    const actOn = compact((nodes ?? []).map(props.findingFor));
    return { count, actOn };
  }, [props]);
  const tracker = useTracker();

  const innerProps = useMemo(
    () => ({ ...props, actOn, count }),
    [props, actOn, count]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
      }}
    >
      <ButtonGroup>
        {hasAssignment && !!tracker && hasAssignModal(props.state, actOn) && (
          <FindingsAssignModal {...innerProps} />
        )}
        {hasIgnoreToggle(props.state) && (
          <FindingsIgnoreModal {...innerProps} />
        )}
        {hasFixModal(props.integration, props.monitor, props.state) && (
          <FindingsFixOrRevertModal {...innerProps} />
        )}
        {hasManagement && hasManageModal(props.integration, props.monitor) && (
          <FindingsManageModal {...innerProps} />
        )}
      </ButtonGroup>
      <div
        style={{
          borderTopRightRadius: "2px",
          borderBottomRightRadius: "2px",
          width: "6.5em",
          padding: "5px",
          backgroundColor: "#f5f5f5",
        }}
      >
        {count || "all"}
        &nbsp;{pluralize("findings", count)}
      </div>
    </div>
  );
};

const MonitorResultsContent: React.FC = () => {
  const { monitorId, findingId, orgSlug } = useParams();
  const [nodeKey] = useQueryParam("node", StringParam);
  const { selected } = useContext(EnvironmentContext);
  const assessmentId = selected?.assessmentId;
  const { current, runAssessmentNow } = useContext(SelectedEnvironmentContext);
  const tenantId = useContext(Tenant);
  const {
    allMonitors,
    isPartial,
    loading,
    prioritized,
    range,
    setRange,
    state: findingsState,
  } = useContext(FindingsContext);
  const { graph, integration, scopeKey, step, runMetaGraph } =
    useContext(ScopeContext);
  const { controls, setControls } = useControls();
  const [searchedNodes, setSearchedNodes] = useState<AnyNode[]>();
  const [selectedNodes, setSelectedNodes] = useState<AnyNode[]>();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const closeEditModal = useCallback(() => setEditModalOpen(false), []);
  const openEditModal = useCallback(() => setEditModalOpen(true), []);

  const monitor = useMemo(
    () =>
      monitorId
        ? prioritized.find((m) => m.monitorId === monitorId) ??
          allMonitors[monitorId]
        : undefined,
    [monitorId, prioritized, allMonitors]
  );

  const monitorControls = useMemo(
    () => ({
      where: (monitor?.search ?? []).map((s) => s.term).join(" "),
      show: monitor?.show ?? "grant",
    }),
    [monitor]
  );

  const inferredControls = useMemo(() => {
    return { ...controls, show: monitorControls.show };
  }, [controls, monitorControls]);

  const findings = useMemo(() => monitor?.scopedFindings, [monitor]);

  const filteredGraph = useMemo<
    DirectedGraph<AssessmentNodes> | undefined
  >(() => {
    if (!monitorId || !graph) return undefined;
    if (!findings) return { nodes: [] };
    const nodes = sortBy(
      findings.map((d) => d.data.node),
      "key"
    );
    return {
      nodes: compact(
        nodes.map(
          (n) =>
            graph.nodes.find((nn) => n.key === nn.key && n.type === nn.type) ??
            syntheticNode(n)
        )
      ),
    };
  }, [monitorId, graph, findings]);

  const openedFindingsNode = useMemo(() => {
    if (!nodeKey) return undefined;
    return searchedNodes?.find((n) => n.key === nodeKey);
  }, [nodeKey, searchedNodes]);

  // TODO: Improve performance if this ever matters
  const findingFor = useCallback(
    (node: Node<any, any>) =>
      findings?.find(
        (d) => d.data.node.key === node.key && d.data.node.type === node.type
      ),
    [findings]
  );

  const extraColumns = useMemo(() => {
    if (!assessmentId || !orgSlug || !monitorId || !findings) return undefined;
    return [
      DetailsColumn((node) => {
        const linkId = findingFor(node)?.id;
        const { key } = node;
        return linkId
          ? {
              disabled: linkId === findingId,
              key: linkId,
              to: `/o/${orgSlug}/${
                AppPaths.Posture
              }/monitors/${monitorId}/findings/${linkId}?scope=${encodeURIComponent(
                scopeKey
              )}&node=${encodeURIComponent(key)}`,
            }
          : undefined;
      }),
      SinceColumn(findingsState, (node) => findingFor(node)?.data.trigger.at),
    ];
  }, [
    assessmentId,
    findingFor,
    findingId,
    findings,
    findingsState,
    monitorId,
    orgSlug,
    scopeKey,
  ]);

  // Is undefined unless user has navigated to a findings page
  const findingDoc = useFirestoreDoc<Finding>(
    findingId && assessmentId
      ? `${toAssessmentPath(tenantId, assessmentId)}/findings/${findingId}`
      : undefined,
    { live: true }
  );

  const unevaluatedMonitorText = useMemo(() => {
    if (monitorId) {
      if (allMonitors[monitorId]?.scopedFindings?.length === 0) {
        return `This monitor has not been evaluated yet. Results for this monitor will be available after the next assessment job.`;
      } else {
        return `This monitor's search term has been updated, and the results on the page are out of date. Updated results for this monitor will be available after the next assessment job.`;
      }
    }
    return "";
  }, [monitorId, allMonitors]);

  const hasBulkSelect = useMemo(
    () =>
      hasIgnoreToggle(findingsState) ||
      hasFixModal(integration, monitor, findingsState),
    [findingsState, integration, monitor]
  );

  const frozen = useMemo(
    () => ({
      show: monitorControls.show,
      terms: monitorControls.where,
    }),
    [monitorControls]
  );

  // Rank scopes by number of applicable findings
  const scopeScoring: ScopeScoring = useMemo(() => {
    if (!monitor) {
      return () => ({ value: 0, label: "0" });
    }
    const scopeCounts = mapValues(monitor.findingsByScope, (ff) => ff.length);
    return (scope) => {
      const k = toKey(scope);
      const minCount = scopeCounts[k] ?? 0;
      const isUnknownCount =
        isPartial && (k !== scopeKey || minCount >= MAX_DISPLAYED_FINDINGS);
      return {
        value: minCount,
        label: isUnknownCount ? `≥ ${String(minCount)}` : String(minCount),
      };
    };
  }, [isPartial, monitor, scopeKey]);

  const convertDate = useCallback(
    ({ data }: FirestoreDoc<Finding>) => new Date(data.trigger.at),
    []
  );

  return monitorId && monitor ? (
    <>
      <Heading
        title={
          <>
            {monitor.label}{" "}
            {monitor.isCustom && (
              <Button type="text" onClick={openEditModal}>
                <EditOutlined
                  style={{
                    fontSize: "20px",
                    verticalAlign: "middle",
                  }}
                />
              </Button>
            )}
          </>
        }
      />
      <EditMonitorModal
        editModalOpen={editModalOpen}
        closeEditModal={closeEditModal}
        modalMonitor={monitor}
      />

      <Typography.Paragraph>
        This monitor is marked as <MonitorSeverity monitor={monitor} />
      </Typography.Paragraph>
      {monitor.description && (
        <>
          <Typography.Title level={4}>Description</Typography.Title>
          <Typography.Paragraph>{monitor.description}</Typography.Paragraph>
        </>
      )}
      <Typography.Title level={4}>Findings</Typography.Title>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "fit-content",
        }}
      >
        {monitor.hasBeenEvaluated === false && (
          <Alert
            type="warning"
            description={unevaluatedMonitorText}
            message="One more thing..."
            action={
              current.isInProgress ? (
                <Typography.Text type="secondary">
                  Assessment job in progress
                </Typography.Text>
              ) : (
                <Button
                  type="default"
                  disabled={!current.isCompleted}
                  onClick={runAssessmentNow}
                >
                  Run an assessment now
                </Button>
              )
            }
            style={{
              marginBottom: 16,
              color: "#614700",
              alignItems: "center",
            }}
          />
        )}
        <VerticalSpacedDiv>
          {findings && (
            <DateHistogram
              data={loading ? undefined : findings} // Smoothly update on findings select
              value={convertDate}
              range={[
                range[0] ?? subDays(new Date(), 30),
                range[1] ?? new Date(),
              ]}
              setRange={setRange}
              label={findingsState === "open" ? "new" : findingsState} // Date on open finding is when it is new
            />
          )}
          <FindingsSelect
            includeAll={runMetaGraph}
            includeRange
            scoring={scopeScoring}
            scopesToInclude={monitor.scopes}
          />
          {
            <AssessmentGraph
              graph={filteredGraph ?? { nodes: [] }}
              controls={inferredControls}
              onControls={setControls}
              onSearch={setSearchedNodes}
              onSelection={hasBulkSelect ? setSelectedNodes : undefined}
              searchExtra={
                hasBulkSelect && (
                  <ActionBar
                    allNodes={searchedNodes}
                    findingFor={findingFor}
                    integration={integration}
                    monitor={monitor}
                    selectedNodes={selectedNodes}
                    state={findingsState}
                  />
                )
              }
              settingsDisables={{ stopOn: !(scopeKey === ALL_SCOPE_SENTINEL) }}
              scopeKey={scopeKey}
              frozen={frozen}
              extraColumns={extraColumns}
              step={step}
            />
          }
        </VerticalSpacedDiv>
      </div>
      {findingId && (
        <FindingDetail node={openedFindingsNode} finding={findingDoc} />
      )}
    </>
  ) : (
    <Spin />
  );
};
